
















































































































import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';
import { getLinkPath } from '../router';
import * as schema from '@/gql/schema';
import { routeName } from '@/common';

@Component({
  apollo: {
    me: {
      skip: function () {
        return this.isLoggedIn == false || this.isAuthInitialized == false;
      },
      query: gql`
        query AppHeader_me {
          me {
            id
            displayName
            profilePictureUrl
            roles {
              colorHex
              displayName
              id
            }
          }
        }
      `,
    },
  },
})
export default class AppHeader extends Vue {
  private me?: schema.User;

  private navItems = {
    about: {
      to: {
        name: routeName('about'),
      },
      tooltip: {
        offset: 20,
      },
      label: 'about',
    },
    browse: {
      to: {
        name: routeName('browse'),
      },
      tooltip: 'Browse all Loungeware games',
      label: 'gallery',
    },
    guestbook: {
      to: {
        name: routeName('guestbook'),
      },
      tooltip: 'Sign our guestbook',
      label: 'Guestbook',
    },
    play: {
      to: {
        name: routeName('play'),
      },
      tooltip: 'Play Loungeware in your browser',
      label: 'play',
    },
    github: {
      to: getLinkPath('github'),
      tooltip: 'Fork Loungeware on Github',
      label: 'github',
    },
    discord: {
      to: getLinkPath('discord'),
      tooltip: 'Join the Loungeware Discord',
      label: 'discord',
    },
  };

  private login() {
    window.location = this.$auth.oAuthLoginUrl;
  }

  private logout() {
    this.$auth.logout();
  }

  private get username() {
    return this?.me?.displayName || 'Unknown';
  }

  private get isLoggedIn() {
    return this.$auth.isLoggedIn;
  }

  private get isAuthInitialized() {
    return this.$auth.isInitialized;
  }
}
