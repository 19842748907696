


























































































import { Component, Vue } from 'vue-property-decorator';
import { getLinkPath } from '../router';
import { numGames, numContributors } from '../common/gamesList';
import gql from 'graphql-tag';
import * as schema from '@/gql/schema';
import { routeName } from '@/common';

//https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
function shuffle(array: any[]) {
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

@Component({
  apollo: {
    pageVisits: {
      fetchPolicy: 'no-cache',
      query: gql`
        query AppFooter_pageVisits($route: String!) {
          pageVisits(route: $route) {
            id
            route
            visits
          }
        }
      `,
      skip() {
        return !this.$route?.fullPath;
      },
      variables() {
        return {
          route: this.$route.fullPath,
        };
      },
    },
  },
})
export default class AppFooter extends Vue {
  private contributorName = '';
  private gameName = '';

  private numGames = numGames;
  private numContributors = numContributors;

  private pageVisits?: schema.PageVisit;

  private items = [
    ['@katsaii', 'coming up with the name "Loungeware"'],
    ['@zandy', 'making jams'],
    ['@meseta', 'making jams'],
    ['@nahoo', 'breaking Beenade'],
    ['@orphilius', 'fixing Beenade'],
    ['@baku', 'solving the case'],
    ['@baku', 'being a dork'],
    ['@mimpy', 'actually fixing Beenade'],
    ['@space', 'starting it all'],
    ['@tfg', 're-writing the wiki over and over again'],
    ['@yosi', 'porting Epic Fire Truck'],
    ['@net8floz', 'making the website'],
    ['@masharcade', 'nerfing cookie dunk'],
    ['@pixelatedpope', 'purging the heretics'],
  ];

  private navItems = {
    about: {
      to: {
        name: routeName('about'),
      },
      label: 'About',
    },
    github: {
      to: getLinkPath('github'),
      label: 'Fork On Github',
    },
    githubWeb: {
      to: getLinkPath('github-web'),
      label: 'View the source',
    },
    discord: {
      to: getLinkPath('discord'),
      label: 'Join Our Discord',
    },
    wiki: {
      to: getLinkPath('wiki'),
      label: 'Read The Wiki',
    },
    wikiLarold: {
      to: getLinkPath('wiki-larold'),
      label: 'Draw A Larold',
    },
    gmDiscord: {
      to: getLinkPath('gm-discord'),
      label: 'GameMaker Discord',
    },
  };

  private index = 0;
  private timeout: any = undefined;

  private mounted() {
    this.nextItem();
  }

  private beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = undefined;
  }

  private nextItem() {
    this.index += 1;
    if (this.index >= this.items.length) {
      this.index = 0;
      this.items = shuffle(this.items);
    }
    this.contributorName = this.items[this.index][0];
    this.gameName = this.items[this.index][1];

    this.timeout = setTimeout(() => {
      this.nextItem();
    }, 7000);
  }
}
