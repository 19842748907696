





























































































































import { Component, Vue } from 'vue-property-decorator';
import { getRouteMetadata, routeName, RoutePath } from '../../common';
import { getLinkPath } from '@/router';
import LaroldImg from '@/components/LaroldImg.vue';

@Component({
  components: { LaroldImg },
  metaInfo() {
    const routeMetadata = getRouteMetadata(
      '/',
      this.$route.params,
      this.$route.query
    );
    return {
      title: routeMetadata.title,
    };
  },
})
export default class About extends Vue {
  private navItems = {
    play: {
      to: {
        name: routeName('play'),
      },
      label: 'play',
    },
    github: {
      to: getLinkPath('github'),
      label: 'Fork On Github',
    },
    discord: {
      to: getLinkPath('discord'),
      label: 'Join Our Discord',
    },
  };
}
